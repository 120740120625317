import { useGlobalContext } from "GlobalContext";
import axios from "axios";
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  load,
  loadDeliveryOrders,
  updateDeliveryOrder,
  verifyOrderPayment,
} from "services/delivery/delivery";
import { listDeliveryLogs } from "services/delivery/deliveryLog";
import { Mixpanel } from "services/mixpanel";
import { LogsFilters } from "shared/components/Logs/types/FiltersHeader.type";
import { Delivery, IDelivery } from "shared/models/delivery";
import { DeliveryOrder, IDeliveryOrder } from "shared/models/deliveryOrder";
import { DeliveryOrderStatus } from "shared/types/deliveryOrder";
import { DeliveryOrderLogEntry } from "shared/types/installation/delivery/deliveryLog";
import { useAppTabContext } from "../../AppContext";
import DeliveryComponent from "./Delivery";
import EditDeliveryModal from "./components/EditDeliveryModal";
import { mountQueryParams } from "./helpers/mountParams";

interface DeliveryContextData {
  delivery?: Delivery;
  loadingDelivery: boolean;
  openEditModal(): void;
  updateDeliveryData(d: IDelivery): void;
  orders: DeliveryOrder[];
  loadingOrders: boolean;
  changeOrderStatus(o: DeliveryOrder, s: DeliveryOrderStatus): void;
  statusLabels: { [key: string]: string };
  getVerifyOrderPayment(transactionId: string): void;
}

interface DeliveryLogsContextData {
  logs: DeliveryOrderLogEntry[];
  isLoadingLogs: boolean;
  logsPage: number;
  setLogsPage(n: number): void;
  logsLastPage: number;
  logsFilters: LogsFilters;
  setLogsFilters(f: LogsFilters): void;
}

const statusLabels = {
  CREATING: "Criando",
  PENDING: "Pendente",
  PREPARING: "Em preparo",
  DELIVERED: "Em entrega",
  SCHEDULED: "Agendado",
  FINISHED: "Finalizado",
  CANCELLED: "Cancelado",
  CANCELLATION_REQUESTED: "Cancelamento solicitado",
} as const;

const DeliveryContext = createContext<DeliveryContextData>({} as DeliveryContextData);
const DeliveryLogsContext = createContext<DeliveryLogsContextData>({} as DeliveryLogsContextData);

export const useDeliveryContext = () => useContext(DeliveryContext);
export const useDeliveryLogsContext = () => useContext(DeliveryLogsContext);

const DeliveryProvider = () => {
  const { confirm } = useGlobalContext();
  const { app } = useAppTabContext();

  const [orders, setOrders] = useState<DeliveryOrder[]>([]);
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [delivery, setDelivery] = useState<Delivery>();
  const [loadingDelivery, setLoadingDelivery] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);

  const openEditModal = useCallback(() => {
    Mixpanel.track("customers-app-delivery-opened-edit");
    setShowEditModal(true);
  }, []);

  const loadDelivery = useCallback(async () => {
    try {
      setLoadingDelivery(true);
      const response = await load(app.idi);
      setDelivery(new Delivery(response.data));
    } catch (error) {
      console.error("Falha ao carregar o delivery", error);
      toast.error("Falha ao carregar o delivery");
    } finally {
      setLoadingDelivery(false);
    }
  }, [app.idi]);

  const loadOrders = useCallback(async () => {
    try {
      setLoadingOrders(true);
      const response = await loadDeliveryOrders(app.idi);
      setOrders(response.data.map((item) => new DeliveryOrder(item)));
    } catch (error) {
      console.error("Falha ao carregar os pedidos", error);
      toast.error("Falha ao carregar os pedidos");
    } finally {
      setLoadingOrders(false);
    }
  }, [app.idi]);

  const updateDeliveryData = useCallback((data: IDelivery) => {
    setDelivery(new Delivery(data));
  }, []);

  const changeOrderStatus = useCallback(
    async (order: DeliveryOrder, status: DeliveryOrderStatus) => {
      confirm(
        `Deseja alterar o status do pedido ${order.id}?`,
        `${statusLabels[order.status]} (${order.status}) para ${statusLabels[status]} (${status})`,
        async () => {
          if (delivery?.idi) {
            try {
              await updateDeliveryOrder(delivery.idi, order.id, {
                newStatus: status,
                oldStatus: order.status,
              });
              setOrders((prevOrders) =>
                prevOrders.map((o) => {
                  if (o.id === order.id) {
                    const orderData: IDeliveryOrder = {
                      id: o.id,
                      customer_name: o.customerName,
                      customer_phone: o.customerPhone,
                      created_at: o.createdAt.format(),
                      updated_at: o.updatedAt.format(),
                      price: o.price,
                      origin: o.origin,
                      status,
                      payments: o.payments,
                      online_payments: o.onlinePayments,
                    };
                    return new DeliveryOrder(orderData);
                  }
                  return o;
                })
              );
              toast.success("Status do pedido alterado");
            } catch (error) {
              console.error("Falha ao alterar status do pedido", error);
              toast.error("Falha ao alterar status do pedido");
            }
          }
        }
      );
    },
    [confirm, delivery?.idi]
  );

  const getVerifyOrderPayment = useCallback(
    async (transactionId: string) => {
      try {
        setLoadingOrders(true);
        await verifyOrderPayment(app.idi.toString(), transactionId);
        await loadOrders();
        toast.success("Status de pagamento do pedido atualizado");
      } catch (error) {
        console.error("Falha ao verificar status de pagamento", error);
        toast.error("Falha ao verificar status de pagamento");
      } finally {
        setLoadingOrders(false);
      }
    },
    [app.idi, loadOrders]
  );

  useEffect(() => {
    loadDelivery();
  }, [loadDelivery]);

  useEffect(() => {
    loadOrders();
  }, [loadOrders]);

  const deliveryData = useMemo(
    () => ({
      delivery,
      loadingDelivery,
      openEditModal,
      updateDeliveryData,
      orders,
      loadingOrders,
      changeOrderStatus,
      statusLabels,
      getVerifyOrderPayment,
    }),
    [
      delivery,
      loadingDelivery,
      openEditModal,
      updateDeliveryData,
      orders,
      loadingOrders,
      changeOrderStatus,
      getVerifyOrderPayment,
    ]
  );

  return (
    <DeliveryContext.Provider value={deliveryData}>
      <DeliveryLogsProvider>
        <DeliveryComponent />
        <EditDeliveryModal isOpen={showEditModal} onClose={() => setShowEditModal(false)} />
      </DeliveryLogsProvider>
    </DeliveryContext.Provider>
  );
};

const DeliveryLogsProvider = ({ children }: { children: React.ReactNode }) => {
  const { app } = useAppTabContext();
  const [logs, setLogs] = useState<DeliveryOrderLogEntry[]>([]);
  const [logsPage, setLogsPage] = useState<number>(1);
  const [logsLastPage, setLogsLastPage] = useState<number>(1);
  const [isLoadingLogs, setIsLoadingLogs] = useState(false);
  const [logsFilters, setLogsFilters] = useState<LogsFilters>({
    description: "",
    startDateTime: null,
    endDateTime: null,
    status: [],
  });

  const loadLogs = useCallback(async () => {
    try {
      setIsLoadingLogs(true);

      const queryParams = mountQueryParams({
        ...logsFilters,
        limit: 20,
        offset: (logsPage - 1) * 20,
        ...(logsFilters.order_id && { order_id: logsFilters.order_id }),
      });
      const response = await listDeliveryLogs(app.idi, queryParams);

      setLogs(response.data.results);
      setLogsLastPage(Math.ceil(response.data.total / response.data.paging.limit));
    } catch (error) {
      console.error("Falha ao carregar os logs", error);
      if (axios.isAxiosError(error) && error.response?.data?.message) {
        const errorMessage = error.response.data.message;
        if (errorMessage === "Description must be at least 4 characters long") {
          toast.error("A descrição deve ter pelo menos 4 caracteres");
        } else {
          toast.error(errorMessage);
        }
      } else {
        toast.error("Falha ao carregar os logs");
      }
      setLogs([]);
      setLogsLastPage(1);
    } finally {
      setIsLoadingLogs(false);
    }
  }, [app.idi, logsFilters, logsPage]);

  useEffect(() => {
    loadLogs();
  }, [loadLogs]);

  useEffect(() => {
    setLogsPage(1);
  }, [logsFilters]);

  const logsData = useMemo(
    () => ({
      logs,
      isLoadingLogs,
      logsPage,
      setLogsPage,
      logsLastPage,
      logsFilters,
      setLogsFilters,
    }),
    [logs, isLoadingLogs, logsPage, logsLastPage, logsFilters]
  );

  return <DeliveryLogsContext.Provider value={logsData}>{children}</DeliveryLogsContext.Provider>;
};

export default DeliveryProvider;
