import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StackDivider,
  Text,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { MdInfoOutline, MdOutlineOpenInNew } from "react-icons/md";
import { toast } from "react-toastify";
import { createTempUser } from "services/app";
import { Mixpanel } from "services/mixpanel";
import { copyToClipboard } from "shared/helpers/clipboard";
import { formatDocument } from "shared/helpers/document";
import { generatePassword } from "shared/helpers/password";
import { useAppTabContext } from "../AppContext";

export type CreateTempUserProps = React.PropsWithChildren<{
  isOpen: boolean;
  onClose(): void;
}>;

const CreateTempUserModal: React.FC<CreateTempUserProps> = ({
  isOpen,
  onClose: onCloseFromProps,
}) => {
  const { app, loadUsers } = useAppTabContext();

  const tempUserLogin = formatDocument(
    (app.idi / 1000000).toFixed(14).replace("0.", "")
  );

  const [login, setLogin] = useState(tempUserLogin);
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const onClose = () => {
    onCloseFromProps();
    setLogin(tempUserLogin);
    setPassword("");
    setSuccess(false);
    setLoading(false);
  };

  const save = () => {
    setLoading(true);

    const generatedPassword = generatePassword();
    setPassword(generatedPassword);

    Mixpanel.track("customers-app-users-submitted-temp-user");
    createTempUser(app.idi, login, generatedPassword)
      .then(() => {
        toast.success("Usuário temporário criado");
        setSuccess(true);
        loadUsers();
      })
      .catch(() => toast.error("Falha ao criar usuário temporário"))
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <Text mb="0.5rem">Criar usuário temporário</Text>
        </ModalHeader>
        <ModalCloseButton isDisabled={loading} />
        <ModalBody as={Stack} gap="1.5rem">
          <FormControl isReadOnly isRequired={!success}>
            <FormLabel fontSize="0.8rem" color="gray.500">
              Login
            </FormLabel>
            <InputGroup>
              <Input
                value={login}
                disabled={loading}
                onChange={(e) => setLogin(e.target.value)}
              />
              <InputRightElement>
                <IconButton
                  variant="link"
                  aria-label="Copiar login"
                  icon={<IoCopyOutline />}
                  onClick={() =>
                    login && copyToClipboard(login, "Login copiado")
                  }
                />
              </InputRightElement>
            </InputGroup>
            {login !== tempUserLogin && (
              <FormErrorMessage>
                Login deve ser {tempUserLogin}
              </FormErrorMessage>
            )}
          </FormControl>

          {success && (
            <FormControl isReadOnly>
              <FormLabel fontSize="0.8rem" color="gray.500">
                Senha
              </FormLabel>
              <InputGroup>
                <Input value={password} />
                <InputRightElement>
                  <IconButton
                    variant="link"
                    aria-label="Copiar senha"
                    icon={<IoCopyOutline />}
                    onClick={() => copyToClipboard(password, "Senha copiada")}
                  />
                </InputRightElement>
              </InputGroup>
            </FormControl>
          )}
        </ModalBody>

        <ModalFooter>
          {success ? (
            <Stack divider={<StackDivider />} gap="1rem">
              <Card bg="yellow.100">
                <CardBody>
                  <HStack gap="1rem">
                    <Icon as={MdInfoOutline} color="yellow.500" />
                    <Text fontSize="0.8rem" fontWeight="400" color="gray.600">
                      <strong>Aviso:</strong> o usuário temporário vai ficar
                      ativo em até 1h. Após esse período, o usuário vai ser
                      deslogado.
                    </Text>
                  </HStack>
                </CardBody>
              </Card>

              <ButtonGroup isAttached variant="outline" colorScheme="yooga">
                <Button
                  leftIcon={<MdOutlineOpenInNew />}
                  flexBasis="50%"
                  onClick={() => window.open("https://app.yooga.com.br/login")}
                >
                  Abrir App
                </Button>
                <Button
                  leftIcon={<MdOutlineOpenInNew />}
                  flexBasis="50%"
                  onClick={() =>
                    window.open("https://painel.yooga.com.br/login")
                  }
                >
                  Abrir Painel
                </Button>
              </ButtonGroup>
            </Stack>
          ) : (
            <Button
              w="100%"
              onClick={save}
              variant="filled"
              isLoading={loading}
            >
              Gerar senha e criar usuário
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateTempUserModal;
