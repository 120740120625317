import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  IconButton,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { IoCopyOutline } from "react-icons/io5";
import { copyToClipboard } from "shared/helpers/clipboard";
import { normalizeJson } from "shared/helpers/json";
import { LogModalProps } from "shared/types/logs";
import HumanReadableJsonLog from "./components/HumanReadableJsonLog";
import UserFriendlyLog from "./components/UserFriendlyLog";

const scrollbarStyles = {
  "&::-webkit-scrollbar": {
    width: "6px",
    height: "6px",
  },
  "&::-webkit-scrollbar-track": {
    background: "gray.200",
    borderRadius: "6px",
  },
  "&::-webkit-scrollbar-thumb": {
    background: "gray.400",
    borderRadius: "6px",
    border: "2px solid gray.200",
  },
  "&::-webkit-scrollbar-thumb:hover": {
    background: "gray.500",
  },
  "&::-webkit-scrollbar-corner": {
    background: "gray.200",
  },
};

const LogModal: React.FC<LogModalProps> = ({ isOpen, onClose: onCloseFromProps, log, title }) => {
  const [loading, setLoading] = useState(false);
  const [isRawView, setIsRawView] = useState(false);

  const formattedLog = normalizeJson(log);
  const bgColor = useColorModeValue("gray.100", "gray.700");
  const parsedLog = JSON.parse(formattedLog);

  const handleClose = () => {
    onCloseFromProps();
    setLoading(false);
  };

  const handleCopy = () => {
    copyToClipboard(formattedLog, "Json copiado");
  };

  const renderHeader = () => (
    <DrawerHeader>
      {title || "Log"}
      {log?.user?.login && ` - Usuário ${log.user.login}`}
    </DrawerHeader>
  );

  const renderTechnicalView = () => (
    <Box display="flex" flexDirection="column">
      <HStack mb={4} justify="space-between" flexShrink={0}>
        <Button
          size="sm"
          variant="ghost"
          onClick={() => setIsRawView(!isRawView)}
          colorScheme="yooga"
        >
          {isRawView ? "Ver formato legível" : "Ver JSON"}
        </Button>
        <IconButton
          aria-label="Copy json log"
          size="sm"
          variant="ghost"
          onClick={handleCopy}
          icon={<IoCopyOutline />}
          colorScheme="yooga"
        />
      </HStack>
      <Box
        flex="1"
        overflowX="auto"
        overflowY="auto"
        minH="0"
        position="relative"
        sx={scrollbarStyles}
      >
        <Box p={2}>
          {isRawView ? (
            <Box as="pre" whiteSpace="pre-wrap" wordBreak="break-word">
              <code>{formattedLog}</code>
            </Box>
          ) : (
            <HumanReadableJsonLog data={parsedLog} />
          )}
        </Box>
      </Box>
    </Box>
  );

  return (
    <Drawer
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={handleClose}
      size="xl"
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton isDisabled={loading} />
        {renderHeader()}
        <DrawerBody>
          <Box
            p={4}
            bg={bgColor}
            borderRadius="md"
            minH="100%"
            display="flex"
            flexDirection="column"
          >
            <Tabs variant="enclosed" flex="1" display="flex" flexDirection="column">
              <TabList>
                <Tab>Visualização Amigável</Tab>
                <Tab>Visualização Técnica</Tab>
              </TabList>
              <TabPanels flex="1" overflow="auto">
                <TabPanel p={0}>
                  <Box overflowY="auto">
                    <UserFriendlyLog data={parsedLog} />
                  </Box>
                </TabPanel>
                <TabPanel p={0}>{renderTechnicalView()}</TabPanel>
              </TabPanels>
            </Tabs>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default LogModal;
