import {
  Button,
  Checkbox,
  CircularProgress,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Select,
} from "@chakra-ui/react";

import { useEffect, useState } from "react";
import { IMaskInput } from "react-imask";
import { documentMasks } from "shared/utils/maskOptions";
import { useCreateInstallationModalContext } from "./CreateInstallationModalContext";

interface CreateInstallationModalProps {
  isOpen: boolean;
  onClose(): void;
}

const CreateInstallationModal: React.FC<CreateInstallationModalProps> = ({ isOpen, onClose }) => {
  const [isContextLoaded, setIsContextLoaded] = useState(false);
  const modalContext = useCreateInstallationModalContext();

  useEffect(() => {
    modalContext && setIsContextLoaded(true);
  }, [modalContext]);

  if (!isContextLoaded) {
    return <CircularProgress isIndeterminate color="yooga.400" />;
  }

  const {
    cnpj,
    setCnpj,
    setBusinessModel,
    setPassword,
    setConfirmPassword,
    errors,
    password,
    confirmPassword,
    handleSubmit,
    loading,
    hasTrialBanner,
    setHasTrialBanner,
  } = modalContext;

  return (
    <Drawer isOpen={isOpen} onClose={onClose} size={"lg"} placement="right">
      <DrawerOverlay />
      <DrawerContent pos={"relative"}>
        <DrawerCloseButton />
        <DrawerHeader>Criar nova instalação</DrawerHeader>

        <DrawerBody>
          <Flex columnGap={"5%"} rowGap={"1rem"} wrap={"wrap"}>
            <FormControl isRequired isInvalid={!!errors?.cnpj}>
              <FormLabel>Documento para login</FormLabel>
              <Input
                as={IMaskInput}
                placeholder="000.000.000-00"
                mask={documentMasks}
                value={cnpj}
                onAccept={(value: string) => setCnpj(value)}
                type="text"
              />
              {errors.cnpj && <FormErrorMessage>{errors.cnpj}</FormErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.password}>
              <FormLabel>Senha</FormLabel>
              <Input
                autoComplete="new-password"
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                value={password}
              />
              {errors.password && <FormErrorMessage>{errors.password}</FormErrorMessage>}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.confirmPassword}>
              <FormLabel>Confirme a senha</FormLabel>
              <Input
                autoComplete="new-password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                value={confirmPassword}
              />
              {errors.confirmPassword && (
                <FormErrorMessage>{errors.confirmPassword}</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isRequired isInvalid={!!errors.businessModel}>
              <FormLabel>Tipo do estabelecimento</FormLabel>
              <Select
                onChange={(e) => setBusinessModel(e.target.value)}
                placeholder="Selecione o tipo do estabelecimento"
              >
                <option value="Outros">Em branco</option>
                <option value="Restaurante">Restaurante</option>
                <option value="Hamburgueria">Lanchonete</option>
                <option value="Hamburgueria">Hamburgueria</option>
                <option value="Doceria">Doceria</option>
                <option value="Açaiteria">Açaiteria</option>
                <option value="Pizzaria">Pizzaria</option>
                <option value="Bar / Petiscaria">Bar / Petiscaria</option>
                <option value="Saladeria">Saladeria</option>
                <option value="Distribuidora de Bebidas">Distribuidora de Bebidas</option>
                <option value="Padaria / Cafeteria">Padaria</option>
                <option value="Padaria / Cafeteria">Cafeteria</option>
                <option value="Sorveteria">Sorveteria</option>
                <option value="Comida Oriental">Comida Oriental</option>
                <option value="Neutro">Outros</option>
              </Select>
              {errors.businessModel && <FormErrorMessage>{errors.businessModel}</FormErrorMessage>}
            </FormControl>
            <FormControl>
              <HStack alignItems={"center"} justifyContent={"flex-start"}>
                <FormLabel mb={0}>Criar sem banner trial?</FormLabel>
                <Checkbox
                  onChange={() => {
                    setHasTrialBanner(!!!hasTrialBanner);
                  }}
                />
              </HStack>
            </FormControl>
          </Flex>
          {/* 
          to do next:
            - apenas delivery?
            - mesas, balcão
            - se mesas, quantas mesas
          */}
        </DrawerBody>

        <DrawerFooter>
          <Button colorScheme="yooga" onClick={handleSubmit} w="100%">
            Salvar
          </Button>
        </DrawerFooter>

        {loading && (
          <Flex
            pos="absolute"
            top="0"
            left="0"
            w="100%"
            h="100%"
            bg="gray.100"
            zIndex="1"
            align="center"
            justify="center"
          >
            <CircularProgress isIndeterminate color="yooga.400" />
          </Flex>
        )}
      </DrawerContent>
    </Drawer>
  );
};

export default CreateInstallationModal;
