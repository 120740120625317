import {
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { LogsFilters } from "./types/FiltersHeader.type";

interface FiltersHeaderProps {
  statusLabels?: { [key: string]: string };
  statusFilterName?: string;
  setLogsFilters(f: LogsFilters): void;
  logsFilters: LogsFilters;
  showOrderId?: boolean;
}

const FiltersHeader: React.FC<FiltersHeaderProps> = ({
  statusLabels,
  statusFilterName,
  setLogsFilters,
  logsFilters,
  showOrderId = false,
}) => {
  const [searchTerm, setSearchTerm] = useState<string>(logsFilters?.description ?? "");
  const [startDate, setStartDate] = useState<string>(logsFilters?.startDateTime ?? "");
  const [endDate, setEndDate] = useState<string>(logsFilters?.endDateTime ?? "");
  const [selectedStatus, setSelectedStatus] = useState<string[]>(logsFilters?.status ?? []);
  const [orderId, setOrderId] = useState<string>(logsFilters?.order_id ?? "");
  const resetFilters = () => {
    setSearchTerm("");
    setStartDate("");
    setEndDate("");
    setOrderId("");
    setLogsFilters({
      description: "",
      startDateTime: "",
      endDateTime: "",
      status: [],
      ...(showOrderId && { order_id: "" }),
    });
  };

  const handleFilters = () => {
    setLogsFilters({
      description: searchTerm,
      startDateTime: startDate,
      endDateTime: endDate,
      status: selectedStatus ?? [],
      ...(showOrderId && { order_id: orderId }),
    });
  };

  const handleStatusChange = (status: string) => {
    if (!statusLabels) return;
    if (status === "Todos") {
      setSelectedStatus(
        selectedStatus.length === Object.keys(statusLabels).length
          ? []
          : [...Object.keys(statusLabels)]
      );
    } else {
      setSelectedStatus((prev) =>
        prev.includes(status) ? prev.filter((s) => s !== status) : [...prev, status]
      );
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleFilters();
    }
  };

  return (
    <Flex direction="column" gap="1rem">
      <HStack spacing={4} mb={4} wrap={{ base: "wrap", md: "wrap" }}>
        <FormControl maxWidth="240px">
          <FormLabel justifySelf={"center"}>Buscar por descrição</FormLabel>
          <Input
            placeholder="Digite para buscar..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={handleKeyPress}
          />
        </FormControl>

        {showOrderId && (
          <FormControl maxWidth="180px">
            <FormLabel justifySelf={"center"}>Order ID</FormLabel>
            <Input
              placeholder="Digite para buscar..."
              value={orderId}
              onChange={(e) => setOrderId(e.target.value)}
              onKeyDown={handleKeyPress}
            />
          </FormControl>
        )}

        {statusLabels && (
          <FormControl maxWidth={"180px"}>
            <FormLabel justifySelf={"center"}>{statusFilterName ?? "Status"}</FormLabel>

            <Menu closeOnSelect={false}>
              <MenuButton w={"100%"} as={Button} rightIcon={<FaChevronDown />}>
                {selectedStatus.length === 0 ||
                selectedStatus.length === Object.keys(statusLabels).length
                  ? "Todos"
                  : `${selectedStatus.length} selecionado${selectedStatus.length > 1 ? "s" : ""}`}
              </MenuButton>

              <MenuList minWidth={"200px"}>
                <MenuItem
                  key={new Date().getTime()}
                  w={"100%"}
                  onClick={() => handleStatusChange("Todos")}
                >
                  <Checkbox isChecked={selectedStatus.length === Object.keys(statusLabels).length}>
                    Todos
                  </Checkbox>
                </MenuItem>

                {Object.keys(statusLabels).map((status) => (
                  <MenuItem
                    key={`${status}-${new Date().getTime()}`}
                    onClick={() => handleStatusChange(status)}
                  >
                    <Checkbox isChecked={selectedStatus.includes(status)}>
                      {statusLabels[status]}
                    </Checkbox>
                  </MenuItem>
                ))}
              </MenuList>
            </Menu>
          </FormControl>
        )}

        <FormControl maxWidth="180px">
          <FormLabel justifySelf={"center"}>Data inicial</FormLabel>
          <Input
            type="date"
            value={String(startDate) ?? ""}
            onChange={(e) =>
              setStartDate(
                e.target.value ? new Date(e.target.value).toISOString().split("T")[0] : ""
              )
            }
            onKeyDown={handleKeyPress}
          />
        </FormControl>

        <FormControl maxWidth="180px">
          <FormLabel justifySelf={"center"}>Data final</FormLabel>
          <Input
            type="date"
            value={String(endDate) ?? ""}
            onChange={(e) =>
              setEndDate(e.target.value ? new Date(e.target.value).toISOString().split("T")[0] : "")
            }
            onKeyDown={handleKeyPress}
          />
        </FormControl>

        <Button alignSelf={"end"} colorScheme="blue" onClick={handleFilters}>
          Filtrar
        </Button>

        <Button alignSelf={"end"} colorScheme="gray" onClick={resetFilters}>
          Limpar Filtros
        </Button>
      </HStack>
    </Flex>
  );
};

export default FiltersHeader;
