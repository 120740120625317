import { CardHeader, HStack, Heading, Icon } from "@chakra-ui/react";
import { RiHistoryFill } from "react-icons/ri";

const LogsHeader = () => (
  <CardHeader>
    <HStack align="center">
      <Icon as={RiHistoryFill} />
      <Heading size="sm">Logs do App</Heading>
    </HStack>
  </CardHeader>
);

export default LogsHeader;
