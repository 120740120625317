export const mountQueryParams = (params: { [key: string]: any }) => {
  return Object.entries(params)
    .filter(
      ([_, value]) =>
        value !== null &&
        value !== undefined &&
        value !== "" &&
        !(Array.isArray(value) && value.length === 0)
    )
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return `${key}=${value.join(",")}`;
      }
      return `${key}=${value}`;
    })
    .join("&");
};
