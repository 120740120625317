import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  HStack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { formatDate } from "shared/helpers/date";
import { LogArray, LogData, LogObject, LogValue } from "shared/types/logs";

const UserFriendlyLog: React.FC<{ data: LogData }> = ({ data }) => {
  const formatValue = (value: LogValue): string => {
    if (value === null || value === undefined) return "Não definido";
    if (typeof value === "boolean") return value ? "Sim" : "Não";
    if (typeof value === "number") return value.toString();
    if (typeof value === "string") {
      if (!isNaN(Date.parse(value))) return formatDate(value);
      return value;
    }
    if (Array.isArray(value)) {
      if (value.length === 0) return "Lista vazia";
      if (typeof value[0] === "object" && value[0] !== null) {
        return `${value.length} itens`;
      }
      return value.map((item) => formatValue(item)).join(", ");
    }
    if (typeof value === "object") return "Objeto";
    return String(value);
  };

  const renderAccordionHeader = (title: string, count?: number) => (
    <AccordionButton width="100%">
      <AccordionIcon />
      <Box flex="1" textAlign="left">
        <HStack>
          <Text fontWeight="bold">{title}</Text>
          {count !== undefined && (
            <Badge colorScheme="blue">
              {count} {count <= 1 ? "item" : "itens"}
            </Badge>
          )}
        </HStack>
      </Box>
    </AccordionButton>
  );

  const renderTable = (headers: string[], items: LogObject[]) => (
    <Table size="sm" variant="simple">
      <Thead>
        <Tr>
          {headers.map((header) => (
            <Th key={header}>{header}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {items.map((item, index) => (
          <Tr key={index}>
            {headers.map((header) => (
              <Td key={header}>{formatValue(item[header])}</Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );

  const renderArrayContent = (array: LogArray, title: string) => {
    if (array.length === 0) return null;
    if (typeof array[0] !== "object" || array[0] === null) {
      return (
        <Tr>
          <Th width="40%">{title}</Th>
          <Td>
            <HStack>
              <Text>{formatValue(array)}</Text>
              <Badge colorScheme="blue">
                {array.length} {array.length <= 1 ? "item" : "itens"}
              </Badge>
            </HStack>
          </Td>
        </Tr>
      );
    }

    return (
      <Tr>
        <Td colSpan={2} p={0}>
          <AccordionItem border="none">
            <h2>{renderAccordionHeader(title, array.length)}</h2>
            <AccordionPanel pb={4}>
              {renderTable(Object.keys(array[0] as LogObject), array as LogObject[])}
            </AccordionPanel>
          </AccordionItem>
        </Td>
      </Tr>
    );
  };

  const renderObjectContent = (obj: LogObject, title: string) => (
    <Tr>
      <Td colSpan={2} p={0}>
        <AccordionItem border="none">
          <h2>{renderAccordionHeader(title)}</h2>
          <AccordionPanel pb={4}>
            <Table size="sm" variant="simple">
              <Tbody>
                {Object.entries(obj).map(([key, value]) => {
                  if (Array.isArray(value)) {
                    return renderArrayContent(value, key);
                  }
                  if (typeof value === "object" && value !== null) {
                    return renderObjectContent(value as LogObject, key);
                  }
                  return (
                    <Tr key={key}>
                      <Th width="40%">{key}</Th>
                      <Td>{formatValue(value)}</Td>
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </AccordionPanel>
        </AccordionItem>
      </Td>
    </Tr>
  );

  const renderContent = (key: string, value: LogValue) => {
    if (Array.isArray(value)) {
      return renderArrayContent(value, key);
    }
    if (typeof value === "object" && value !== null) {
      return renderObjectContent(value as LogObject, key);
    }
    return (
      <Tr key={key}>
        <Th width="40%">{key}</Th>
        <Td>{formatValue(value)}</Td>
      </Tr>
    );
  };

  return (
    <Accordion allowMultiple>
      <Table
        size="sm"
        variant="simple"
        sx={{ "& tr": { borderColor: "gray.300", borderWidth: "0 0 0 1px" } }}
      >
        <Tbody>{Object.entries(data).map(([key, value]) => renderContent(key, value))}</Tbody>
      </Table>
    </Accordion>
  );
};

export default UserFriendlyLog;
