import { Box, IconButton, Td, Tooltip, Tr } from "@chakra-ui/react";
import { useMemo } from "react";
import { FaExternalLinkAlt } from "react-icons/fa";
import { AppLogEntry } from "shared/types/installation/app/appLog";
import { DeliveryOrderLogEntry } from "shared/types/installation/delivery/deliveryLog";

type FormattedLog = (AppLogEntry | DeliveryOrderLogEntry) & {
  truncatedDescription: string;
  displayDescription: string;
  formattedDate: string;
};

interface LogsTableRowProps {
  log: FormattedLog;
  onOpenLog: (log: AppLogEntry | DeliveryOrderLogEntry) => void;
  showOrderId?: boolean;
}

const LogsTableRow = ({ log, onOpenLog, showOrderId = false }: LogsTableRowProps) => {
  const modalLog = useMemo(() => {
    const { displayDescription, truncatedDescription, formattedDate, ...rest } = log;
    return rest;
  }, [log]);

  return (
    <Tr key={log.id}>
      {showOrderId && <Td textAlign="center">{(log as DeliveryOrderLogEntry).order_id}</Td>}
      {!showOrderId && <Td textAlign="center">{(log as AppLogEntry).type}</Td>}
      <Td>
        <Tooltip
          label={
            <Box maxHeight="600px" whiteSpace="pre-wrap">
              {log.truncatedDescription}
            </Box>
          }
          aria-label="Full description"
          hasArrow
          placement="top"
          bg="gray.300"
          color="black"
          maxW="400px"
        >
          {log.displayDescription}
        </Tooltip>
      </Td>
      <Td textAlign="center">{log?.user?.login || "-"}</Td>
      <Td textAlign="center">{log.version}</Td>
      <Td textAlign="center">{log.formattedDate}</Td>
      <Td textAlign="center">
        <IconButton
          aria-label="Ver detalhes"
          icon={<FaExternalLinkAlt />}
          variant="ghost"
          size="sm"
          onClick={() => onOpenLog(modalLog)}
        />
      </Td>
    </Tr>
  );
};

export default LogsTableRow;
