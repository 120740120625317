export const documentMasks = [
  { mask: "000.000.000-00", maxLength: 11 }, // CPF
  { mask: "00.000.000/0000-00" }, // CNPJ
];

export const phoneMasks = [
  { mask: "(00) 0000-0000", maxLength: 10 }, // Fixo
  { mask: "(00) 00000-0000", maxLength: 11 }, // Celular
];

export const cepMask = "00000-000";

export const stateMask = "aa";
