import moment from "moment";

/**
 * Interface base para logs, garantindo que os objetos tenham pelo menos as propriedades necessárias.
 */
interface BaseLog {
  description: string;
  created_at: string | Date;
}

/**
 * Trunca uma string para um determinado número de caracteres, adicionando "..." se necessário.
 */
export const truncateString = (str: string, num: number): string => {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + "...";
};

/**
 * Formata a descrição de um log, substituindo ',' por quebras de linha.
 */
export const formatDescription = (description: string): string => {
  return description.replace(/,/g, "\n");
};

/**
 * Formata a lista de logs para exibição na tabela, garantindo que os logs tenham pelo menos as propriedades necessárias,
 * mas permitindo que tenham propriedades adicionais.
 */
export const formatLogs = <T extends BaseLog>(
  logs: T[]
): (T & {
  truncatedDescription: string;
  displayDescription: string;
  formattedDate: string;
})[] => {
  return logs?.map((log) => ({
    ...log,
    truncatedDescription: truncateString(formatDescription(log.description), 490),
    displayDescription: truncateString(log.description, 60),
    formattedDate: moment(log.created_at).format("DD/MM/YYYY HH:mm:ss"),
  }));
};
