import axios from "axios";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { createContext, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { listMarketplaceByIdi, updateMarketplacePayment } from "services/integratedPayment";
import { Marketplace } from "shared/models/marketplace";
import { Driver } from "shared/types/marketplace";
import { IMarketplacePayment, PAYMENT_TYPE } from "shared/types/marketplacePayment";
import { useAppTabContext } from "../../AppContext";
import getPixDriver from "./helpers/getPixDriver";
import IntegratedPayment from "./IntegratedPayment";

interface IntegratedPaymentContextData {
  marketplace: Marketplace;
  getDriver: Driver | null;
  loading: boolean;
  isRegistered: boolean;
  setLoading(b: boolean): void;
  handleSubmit(isPixEnabled: boolean, currentValue: string, initialValue: string): void;
}

const Context = createContext<IntegratedPaymentContextData>({} as IntegratedPaymentContextData);

export const useIntegratedPaymentContext = () => useContext(Context);

const IntegratedPaymentContext = () => {
  const { app } = useAppTabContext();
  const { customer } = useCustomerContext();

  const [loading, setLoading] = useState(false);

  const [isRegistered, setIsRegistered] = useState(true);

  const [marketplace, setMarketplace] = useState<Marketplace>({} as Marketplace);

  const [driver, setDriver] = useState<Driver | null>(null);

  const parsePayload = (currentValue: string, initialValue: string) => {
    let current: PAYMENT_TYPE[] = [];
    let old: PAYMENT_TYPE[] = [];

    if (currentValue === "1") current.push(PAYMENT_TYPE.UM_REAL);
    if (currentValue === "2") current.push(PAYMENT_TYPE.VINTE_CENTAVOS);
    if (currentValue === "3") current.push(PAYMENT_TYPE.NOVENTA_CENTAVOS);
    if (currentValue === "4") current.push(PAYMENT_TYPE.SESSENTA_CENTAVOS);

    if (initialValue === "1") old.push(PAYMENT_TYPE.UM_REAL);
    if (initialValue === "2") old.push(PAYMENT_TYPE.VINTE_CENTAVOS);
    if (initialValue === "3") old.push(PAYMENT_TYPE.NOVENTA_CENTAVOS);
    if (initialValue === "4") old.push(PAYMENT_TYPE.SESSENTA_CENTAVOS);

    return {
      customerId: customer.id,
      idi: app.idi,
      marketplaceFees: current,
      marketplaceFeesOld: old,
    };
  };

  const handleSubmit = (isPixEnabled: boolean, currentValue: string, initialValue: string) => {
    if (isPixEnabled === false) {
      toast.error("Necessário ativar o toogle!");
      return;
    }
    setLoading(true);

    const payload: IMarketplacePayment = parsePayload(currentValue, initialValue);

    updateMarketplacePayment(payload)
      .then(() => {
        toast.success("Dados atualizados com sucesso!");
      })
      .catch((error) => {
        console.error("Falha ao atualizar dados de pagamento", error);
        toast.error("Falha ao atualizar dados de pagamento!");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    listMarketplaceByIdi(String(app.idi))
      .then((res) => {
        const marketplace = new Marketplace(res.data);

        setMarketplace(marketplace);
      })
      .catch((error) => {
        if (axios.isAxiosError(error) && error.response?.status !== 404) {
          console.error("Falha ao carregar o marketplace", error);
        }

        if (error.response && error.response.status === 422) {
          setIsRegistered(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, [app.idi, loading]);

  useEffect(() => {
    if (marketplace.drivers) {
      setDriver(getPixDriver(marketplace.drivers));
    }
  }, [marketplace.drivers]);

  const data: IntegratedPaymentContextData = {
    marketplace,
    getDriver: driver,
    loading,
    isRegistered,
    setLoading,
    handleSubmit,
  };

  return (
    <Context.Provider value={data}>
      <IntegratedPayment />
    </Context.Provider>
  );
};

export default IntegratedPaymentContext;
