import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { IMaskInput } from "react-imask";
import { documentMasks, phoneMasks } from "shared/utils/maskOptions";
import { useCreatePartialCustomerContext } from "./FormContext";

const CreatePartialForm = () => {
  const { cnpj, name, telephone, contact_email, loading, initializing, submit } =
    useCreatePartialCustomerContext();

  return (
    <Card
      padding={".5rem 1.5rem"}
      margin={{ base: "1rem auto", md: "3rem auto" }}
      minW={{ lg: "740px" }}
      w={{ base: "90%", lg: "60%" }}
    >
      {initializing ? (
        <CardBody as={Flex} align="center" justify="center">
          <CircularProgress isIndeterminate />
        </CardBody>
      ) : (
        <>
          <CardHeader>Criação de Customer Parcial</CardHeader>
          <CardBody>
            <Flex columnGap="5%" rowGap="1rem" wrap="wrap">
              <FormControl isRequired flexBasis={{ base: "100%", md: "47.5%" }} isReadOnly>
                <FormLabel fontSize="0.8rem" color="gray.500">
                  CPF/CNPJ
                </FormLabel>
                <Input as={IMaskInput} mask={documentMasks} value={cnpj} type="text" disabled />
              </FormControl>

              <FormControl isRequired flexBasis={{ base: "100%", md: "47.5%" }}>
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Nome Fantasia
                </FormLabel>
                <Input value={name} type="text" disabled />
              </FormControl>

              <FormControl isRequired flexBasis={{ base: "100%", md: "47.5%" }}>
                <FormLabel fontSize="0.8rem" color="gray.500">
                  E-mail
                </FormLabel>
                <Input value={contact_email} type="email" disabled />
              </FormControl>

              <FormControl isRequired flexBasis={{ base: "100%", md: "47.5%" }}>
                <FormLabel fontSize="0.8rem" color="gray.500">
                  Telefone
                </FormLabel>
                <Input as={IMaskInput} mask={phoneMasks} value={telephone} type="text" disabled />
              </FormControl>
            </Flex>
            <Button
              my={"1rem"}
              type="submit"
              size={"md"}
              variant={"filled"}
              isLoading={loading}
              onClick={submit}
            >
              Criar Customer
            </Button>
          </CardBody>
        </>
      )}
    </Card>
  );
};
export default CreatePartialForm;
