import { Table, TableContainer, Tbody, Th, Thead, Tr } from "@chakra-ui/react";
import { AppLogEntry } from "shared/types/installation/app/appLog";
import { DeliveryOrderLogEntry } from "shared/types/installation/delivery/deliveryLog";
import LogsTableRow from "./LogsTableRow";

type FormattedLog = (AppLogEntry | DeliveryOrderLogEntry) & {
  truncatedDescription: string;
  displayDescription: string;
  formattedDate: string;
};

interface LogsTableProps {
  logs: FormattedLog[];
  onOpenLog: (log: AppLogEntry | DeliveryOrderLogEntry) => void;
  showOrderId?: boolean;
}

const LogsTable = ({ logs, onOpenLog, showOrderId = false }: LogsTableProps) => {
  return (
    <TableContainer>
      <Table variant="simple" size="sm">
        <Thead>
          <Tr h="3.5rem">
            {showOrderId && (
              <Th textAlign="center" whiteSpace="break-spaces" w="100px">
                ID do Pedido
              </Th>
            )}
            {!showOrderId && (
              <Th textAlign="center" whiteSpace="break-spaces" w="100px">
                Categoria
              </Th>
            )}
            <Th textAlign="center" whiteSpace="break-spaces" w="200px">
              Descrição
            </Th>
            <Th textAlign="center" whiteSpace="break-spaces" w="120px">
              Login
            </Th>
            <Th textAlign="center" whiteSpace="break-spaces" w="100px">
              Versão
            </Th>
            <Th textAlign="center" whiteSpace="break-spaces" w="150px">
              Criado em
            </Th>
            <Th textAlign="center" whiteSpace="break-spaces" w="80px">
              Ver detalhes
            </Th>
          </Tr>
        </Thead>

        <Tbody>
          {logs.map((log) => (
            <LogsTableRow key={log.id} log={log} onOpenLog={onOpenLog} showOrderId={showOrderId} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default LogsTable;
