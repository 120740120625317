import { createContext, useCallback, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { listInstallationLogs } from "services/installation/installationLog";
import { LogsFilters } from "shared/components/Logs/types/FiltersHeader.type";
import { AppLogEntry } from "shared/types/installation/app/appLog";
import { useAppTabContext } from "../../AppContext";
import { mountQueryParams } from "../Delivery/helpers/mountParams";
import Logs from "./Logs";

interface LogsContextData {
  logs: AppLogEntry[];
  isLoadingLogs: boolean;
  page: number;
  setPage(n: number): void;
  logsLastPage: number;
  setLogsFilters(f: LogsFilters): void;
  categoryTypes: { [key: string]: string };
  logsFilters: LogsFilters;
}

const categoryTypes = {
  PRODUCT: "Produto",
  CATEGORY: "Categoria",
  USER: "Usuário",
  PAYMENT_METHOD: "Forma de pagamento",
  CASH_REGISTER: "Caixa",
};

const Context = createContext<LogsContextData>({} as LogsContextData);

export const useLogContext = () => useContext(Context);

const LogsContext = () => {
  const { app, isLoadingLogs, setIsLoadingLogs } = useAppTabContext();

  const [logs, setLogs] = useState<AppLogEntry[]>([]);
  const [logsLastPage, setLogsLastPage] = useState<number>(1);
  const [logsFilters, setLogsFilters] = useState<LogsFilters>({
    description: "",
    startDateTime: null,
    endDateTime: null,
    status: [],
  });
  const [page, setPage] = useState<number>(1);

  const fetchLogs = useCallback(async () => {
    try {
      setIsLoadingLogs(true);
      const queryParams = mountQueryParams({
        ...logsFilters,
        limit: 20,
        offset: (page - 1) * 20,
      });
      const response = await listInstallationLogs(app.idi, queryParams);

      setLogs(response.data.results);
      setLogsLastPage(Math.ceil(response.data.total / response.data.paging.limit));
    } catch (error) {
      console.error("Falha ao carregar os logs da instalação", error);
      toast.error("Falha ao carregar os logs da instalação");
    } finally {
      setIsLoadingLogs(false);
    }
  }, [app.idi, logsFilters, page, setIsLoadingLogs]);

  useEffect(() => {
    fetchLogs();
  }, [fetchLogs]);

  useEffect(() => {
    setPage(1);
  }, [logsFilters]);

  const data: LogsContextData = {
    logs,
    isLoadingLogs,
    page,
    setPage,
    logsLastPage,
    setLogsFilters,
    categoryTypes,
    logsFilters,
  };

  return (
    <Context.Provider value={data}>
      <Logs />
    </Context.Provider>
  );
};

export default LogsContext;
