import { AxiosResponse } from "axios";
import { AppLogResponse } from "services/interfaces/installation/app/logResponse";
import { apiInstallationApp } from "../http";

export function listInstallationLogs(
  idi: number,
  queryParams: string
): Promise<AxiosResponse<AppLogResponse>> {
  return queryParams
    ? apiInstallationApp.get(`${idi}/logs?${queryParams}`)
    : apiInstallationApp.get(`${idi}/logs`);
}
