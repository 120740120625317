interface JsonParseOptions {
  maxDepth?: number;
  currentDepth?: number;
}

type JsonValue = string | number | boolean | null | JsonObject | JsonArray;
type JsonObject = { [key: string]: JsonValue } | { [key: string]: any };
type JsonArray = JsonValue[];

type PipeProcessedResult = JsonObject & {
  message: string;
  data?: JsonValue | JsonValue[];
};

/**
 * Attempts to parse a string as JSON, handling both regular and escaped JSON
 * @param str The string to parse
 * @param options Options for parsing
 * @returns Parsed object or original string if parsing fails
 */
const parseJsonString = (str: string, options: JsonParseOptions = {}): JsonValue => {
  try {
    // Try direct parsing first
    const parsed = JSON.parse(str);
    return processValue(parsed, options);
  } catch {
    try {
      // Try parsing with unescaped quotes
      const parsed = JSON.parse(str.replace(/\\"/g, '"'));
      return processValue(parsed, options);
    } catch {
      return str;
    }
  }
};

/**
 * Processes a string that may contain pipe-separated values and JSON
 * @param str The string to process
 * @param options Options for parsing
 * @returns Processed object or original string
 */
const processDescriptionWithPipes = (str: string, options: JsonParseOptions = {}): JsonValue => {
  const parts = str.split("|").map((part) => part.trim());

  if (parts.length === 1) {
    return str;
  }

  const result: PipeProcessedResult = { message: parts[0] };

  parts.slice(1).forEach((part, index) => {
    try {
      const [, ...valueParts] = part.split(":").map((s) => s.trim());
      const value = valueParts.join(":").trim();

      if (value) {
        result.data = parseJsonString(value, options);
      }
    } catch {
      result[`part${index + 1}`] = part;
    }
  });

  return result;
};

/**
 * Recursively processes an object to parse any nested JSON strings
 * @param value The value to process
 * @param options Options for parsing
 * @returns Processed value with nested JSON strings parsed
 */
const processValue = (value: JsonValue, options: JsonParseOptions = {}): JsonValue => {
  const { maxDepth = 10, currentDepth = 0 } = options;

  if (currentDepth >= maxDepth) {
    return value;
  }

  if (typeof value === "string") {
    if (value.includes("|")) {
      return processDescriptionWithPipes(value, { maxDepth, currentDepth: currentDepth + 1 });
    }
    if (value.trim().startsWith("{") || value.trim().startsWith("[")) {
      return parseJsonString(value, { maxDepth, currentDepth: currentDepth + 1 });
    }
    return value;
  }

  if (Array.isArray(value)) {
    return value.map((item) => processValue(item, { maxDepth, currentDepth: currentDepth + 1 }));
  }

  if (value && typeof value === "object") {
    const result: JsonObject = {};
    for (const [key, val] of Object.entries(value)) {
      result[key] = processValue(val, { maxDepth, currentDepth: currentDepth + 1 });
    }
    return result;
  }

  return value;
};

/**
 * Normalizes JSON data by parsing nested JSON strings and handling escaped content
 * @param obj The object to normalize
 * @param indent Number of spaces for indentation (only used when converting back to string)
 * @returns Normalized object or string if conversion to string is needed
 */
export const normalizeJson = (obj: JsonValue, indent: number = 2): string => {
  const parsed = processValue(obj);
  return JSON.stringify(parsed, null, indent);
};
