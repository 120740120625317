import { AxiosResponse } from "axios";
import { DeliveryLogResponse } from "services/interfaces/installation/delivery/logResponse";
import { apiInstallationDelivery } from "../http";

export function listDeliveryLogs(
  idi: number,
  queryParams: string
): Promise<AxiosResponse<DeliveryLogResponse>> {
  return queryParams
    ? apiInstallationDelivery.get(`order/${idi}/logs?${queryParams}`)
    : apiInstallationDelivery.get(`order/${idi}/logs`);
}
