import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Tooltip,
} from "@chakra-ui/react";
import { useGlobalContext } from "GlobalContext";
import { useState } from "react";
import { FaEllipsisVertical } from "react-icons/fa6";
import { toast } from "react-toastify";
import { blockAllApps, removeTrialBannerAllApps } from "services/app";
import { Mixpanel } from "services/mixpanel";
import { formatDocument } from "shared/helpers/document";
import { usePermissions } from "shared/hooks/usePermissions";
import { useCustomerContext } from "../CustomerContext";

const HeaderOptions = () => {
  const { e } = usePermissions();
  const { info, loadApps, customer } = useCustomerContext();
  const { confirmByText, confirm } = useGlobalContext();
  const [loading, setLoading] = useState(false);

  const blockApps = () => {
    confirmByText(
      `Bloquear TODOS os Apps do documento ${formatDocument(info.document)}`,
      "Digite CONFIRMAR para continuar",
      () => {
        Mixpanel.track("customers-customer-clicked-block-all-apps");
        setLoading(true);
        blockAllApps(info.document, customer.id)
          .then(() => {
            toast.success("Apps do documento bloqueados");
            loadApps();
          })
          .catch(() => {
            toast.error("Falha ao bloquear todos os apps");
          })
          .finally(() => setLoading(false));
      }
    );
  };

  const onRemoveTrialBanner = () => {
    confirm(
      `Remover a tarja de teste para os IDIs do documento ${formatDocument(
        info.document
      )}?`,
      "Deseja continuar?",
      () => {
        setLoading(true);

        removeTrialBannerAllApps(info.document, customer.id)
          .then(() => {
            toast.success("Tarja removida");
            loadApps();
          })
          .catch(() => {
            toast.error("Falha ao remover a tarja");
          })
          .finally(() => setLoading(false));
      }
    );
  };

  return info.yooga_apps?.length > 0 ? (
    <Menu>
      <MenuButton
        as={IconButton}
        variant="ghost"
        aria-label="options"
        icon={<FaEllipsisVertical />}
      />
      <MenuList>
        <MenuItem
          as={Button}
          isLoading={loading}
          onClick={
            e("installation-card-details-block-update") ? blockApps : undefined
          }
          closeOnSelect={false}
          fontWeight="500"
          size="sm"
          isDisabled={!e("installation-card-details-block-update")}
          title={
            !e("installation-card-details-block-update")
              ? "Sem permissão"
              : undefined
          }
        >
          Bloquear todos os Apps
        </MenuItem>

        <Tooltip
          label={
            !e("installation-card-details-remove-trial-banner")
              ? "Sem permissão"
              : undefined
          }
          hasArrow
          bg="gray.500"
          color="white"
          isDisabled={e("installation-card-details-remove-trial-banner")}
        >
          <MenuItem
            as={Button}
            isLoading={loading}
            onClick={
              e("installation-card-details-remove-trial-banner")
                ? onRemoveTrialBanner
                : undefined
            }
            closeOnSelect={false}
            fontWeight="500"
            size="sm"
          >
            Remover tarja teste de todos os Apps
          </MenuItem>
        </Tooltip>
      </MenuList>
    </Menu>
  ) : null;
};

export default HeaderOptions;
