import { Box, HStack, Text, VStack } from "@chakra-ui/react";
import { LogData, LogValue } from "shared/types/logs";

interface HumanReadableJsonLogProps {
  data: LogData;
  level?: number;
}

const HumanReadableJsonLog: React.FC<HumanReadableJsonLogProps> = ({ data, level = 0 }) => {
  const renderValue = (value: LogValue, key: string) => {
    if (value === null) return <Text color="gray.500">null</Text>;
    if (value === undefined) return <Text color="gray.500">undefined</Text>;
    if (typeof value === "boolean") return <Text color="purple.500">{value.toString()}</Text>;
    if (typeof value === "number") return <Text color="orange.500">{value}</Text>;
    if (typeof value === "string")
      return (
        <Text color="green.500" whiteSpace="pre-wrap" wordBreak="break-word">
          "{value}"
        </Text>
      );
    if (Array.isArray(value)) {
      return (
        <VStack align="start" spacing={1} pl={2} pt={2}>
          {value.map((item, index) => (
            <Box key={index}>{renderValue(item, `${key}[${index}]`)}</Box>
          ))}
        </VStack>
      );
    }
    if (typeof value === "object") {
      return (
        <VStack align="start" spacing={1} pl={2} pt={4}>
          {Object.entries(value).map(([k, v]) => (
            <Box key={k}>
              <HStack align="start">
                <Text fontWeight="bold" color="blue.600" whiteSpace="nowrap">
                  {k}:
                </Text>
                {renderValue(v, k)}
              </HStack>
            </Box>
          ))}
        </VStack>
      );
    }
    return <Text>{String(value)}</Text>;
  };

  return (
    <VStack align="start" spacing={2}>
      {Object.entries(data).map(([key, value]) => (
        <Box key={key}>
          <HStack align="start">
            <Text fontWeight="bold" color="blue.600" whiteSpace="nowrap">
              {key}:
            </Text>
            {renderValue(value, key)}
          </HStack>
        </Box>
      ))}
    </VStack>
  );
};

export default HumanReadableJsonLog;
