import { TabPanel } from "@chakra-ui/react";
import BaseLogs from "shared/components/Logs/BaseLogs";
import { useDeliveryContext, useDeliveryLogsContext } from "../../DeliveryContext";

const DeliveryLogs = () => {
  const { statusLabels } = useDeliveryContext();
  const { logs, isLoadingLogs, logsPage, setLogsPage, logsLastPage, logsFilters, setLogsFilters } =
    useDeliveryLogsContext();

  return (
    <BaseLogs
      isLoadingLogs={isLoadingLogs}
      logs={logs}
      page={logsPage}
      setPage={setLogsPage}
      logsLastPage={logsLastPage}
      setLogsFilters={setLogsFilters}
      logsFilters={logsFilters}
      statusLabels={statusLabels}
      showOrderId={true}
      title="Log de Pedido"
      wrapper={TabPanel}
    />
  );
};

export default DeliveryLogs;
