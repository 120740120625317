import {
  Button,
  Card,
  CardBody,
  Checkbox,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Stack,
  Text,
  useRadioGroup,
} from "@chakra-ui/react";
import { useCustomerContext } from "pages/Customer/CustomerContext";
import { useEffect, useState } from "react";
import { FaChevronDown } from "react-icons/fa";
import { MdInfoOutline } from "react-icons/md";
import { IMaskInput } from "react-imask";
import { toast } from "react-toastify";
import { updateApp, updateAppInfo } from "services/app";
import { AppInfoRequestData } from "services/interfaces/app";
import { Mixpanel } from "services/mixpanel";
import RadioCard from "shared/components/form/RadioCard";
import timezones from "shared/constants/timezones";
import { App, IApp } from "shared/models/app";
import { documentMasks } from "shared/utils/maskOptions";
import { useAppTabContext } from "../AppContext";

interface EditAppDetailsModalProps {
  isOpen: boolean;
  onClose(): void;
}

const EditAppDetailsModal: React.FC<EditAppDetailsModalProps> = ({ isOpen, onClose }) => {
  const { customer } = useCustomerContext();
  const { app, setApp } = useAppTabContext();
  const [loading, setLoading] = useState<boolean>(false);

  const [document, setDocument] = useState(app.document);
  const [name, setName] = useState(app.nickname);
  const [tradeName, setTradeName] = useState(app.tradeName);
  const [timezone, setTimezone] = useState(app.timezone);
  const [botModule, setBotModule] = useState<boolean>(!!app.botModule);
  const [emissorModule, setEmissorModule] = useState<boolean>(!!app.emissorModule);
  const [point, setPoint] = useState<"1" | "0">(String(app.tableModule) as "1" | "0");

  const { getRootProps, getRadioProps } = useRadioGroup({
    value: point,
    onChange: (e) => setPoint(e as "1" | "0"),
  });
  const group = getRootProps();

  const submit = () => {
    setLoading(true);

    const modulesPayload: IApp = {
      ...app.originalPayload,
      modulo_bot: botModule ? 1 : 0,
      modulo_emissor: emissorModule ? 1 : 0,
      modulo_mesa: Number(point) as 1 | 0,
    };

    const infoPayload: AppInfoRequestData = {
      customerId: customer.id,
      oldCnpj: app.document,
      newCnpj: document,
      oldTradeName: app.tradeName,
      newTradeName: tradeName,
      oldName: app.nickname,
      newName: name,
      oldTimeZone: app.timezone,
      newTimeZone: timezone,
    };
    Mixpanel.track("customers-app-details-submitted-edit");

    Promise.all([updateApp(modulesPayload), updateAppInfo(app.idi, infoPayload)])
      .then(() => {
        toast.success("Informações do app atualizadas");
        setApp(
          new App({
            ...modulesPayload,
            cnpj: document,
            razao_social: tradeName,
            apelido: name,
            timezone,
          })
        );
        onClose();
      })
      .catch(() => toast.error("Falha ao atualizar informações do app"))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    setDocument(app.document);
    setName(app.nickname);
    setTradeName(app.tradeName);
    setTimezone(app.timezone);
    setPoint(String(app.tableModule) as "1" | "0");
    setBotModule(!!app.botModule);
    setEmissorModule(!!app.emissorModule);
  }, [app, isOpen]);

  return (
    <Drawer
      closeOnOverlayClick={!loading}
      closeOnEsc={!loading}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <DrawerOverlay />

      <DrawerContent>
        <DrawerCloseButton isDisabled={loading} />
        <DrawerHeader>Editar Detalhes do App</DrawerHeader>

        <DrawerBody>
          <Stack direction={{ base: "column", md: "row" }}>
            <FormControl>
              <FormLabel>
                <Text variant="label">Documento</Text>
                <Input
                  as={IMaskInput}
                  mask={documentMasks}
                  value={document}
                  onAccept={(value: string) => setDocument(value)}
                />
              </FormLabel>
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text variant="label">Nome</Text>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
              </FormLabel>
            </FormControl>
          </Stack>

          <Stack mt="1rem" direction={{ base: "column", md: "row" }}>
            <FormControl>
              <FormLabel>
                <Text variant="label">Razão social</Text>
                <Input value={tradeName} onChange={(e) => setTradeName(e.target.value)} />
              </FormLabel>
            </FormControl>

            <FormControl>
              <FormLabel>
                <Text variant="label">Fuso Horário</Text>
                <Menu matchWidth computePositionOnMount>
                  <MenuButton
                    as={Button}
                    w="100%"
                    variant="outline"
                    textAlign="left"
                    rightIcon={<FaChevronDown />}
                  >
                    <Text fontWeight="400">{timezone}</Text>
                  </MenuButton>
                  <MenuList maxH="20rem" overflowY="auto">
                    {Object.keys(timezones).map((country) => (
                      <Stack key={country}>
                        <MenuGroup title={country}>
                          {timezones[country].map((tz) => (
                            <MenuItem key={tz} fontSize="0.8rem" onClick={() => setTimezone(tz)}>
                              {tz}
                            </MenuItem>
                          ))}
                        </MenuGroup>
                        <MenuDivider />
                      </Stack>
                    ))}
                  </MenuList>
                </Menu>
              </FormLabel>
            </FormControl>
          </Stack>

          <Divider my="1.5rem" />

          <FormControl>
            <FormLabel>Ponto de venda</FormLabel>

            <HStack {...group}>
              <RadioCard isDisabled={loading} {...getRadioProps({ value: "1" })}>
                Mesas
              </RadioCard>
              <RadioCard isDisabled={loading} {...getRadioProps({ value: "0" })}>
                Balcão
              </RadioCard>
            </HStack>
          </FormControl>

          <FormControl mt="2rem">
            <FormLabel>Módulos</FormLabel>
            <Stack spacing={1}>
              <Checkbox
                isDisabled={loading}
                isChecked={botModule}
                onChange={() => setBotModule(!botModule)}
              >
                ChatBot
              </Checkbox>
              <Checkbox
                isDisabled={loading}
                isChecked={emissorModule}
                onChange={() => setEmissorModule(!emissorModule)}
              >
                Emissor
              </Checkbox>
            </Stack>
          </FormControl>
        </DrawerBody>
        <DrawerFooter>
          <Stack w="100%">
            {document !== app.document && (
              <Card bg="red.400" color="white">
                <CardBody>
                  <HStack>
                    <Icon as={MdInfoOutline} />
                    <Text>
                      Ao realizar a troca do documento, a instalação irá se desvincular do customer
                      atual.
                    </Text>
                  </HStack>
                </CardBody>
              </Card>
            )}

            <Button isLoading={loading} onClick={submit} colorScheme="yooga">
              Salvar
            </Button>
          </Stack>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default EditAppDetailsModal;
