import { useCustomerContext } from "pages/Customer/CustomerContext";
import React, { createContext, useContext, useEffect, useState } from "react";

import { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import { create } from "services/installation/installation";
import { CreateInstallationRequestData } from "services/interfaces/installation/installation";
import CreateInstallationModal from "./CreateInstallationModal";
import validateInstallationForm, { IErrors } from "./validation";

interface CreateInstallationModalProps {
  isOpen: boolean;
  onClose(): void;
}

interface CreateInstallationModalContextData {
  loading: boolean;
  setLoading(b: boolean): void;
  cnpj: string;
  setCnpj(s: string): void;
  businessModel: string;
  setBusinessModel(s: string): void;
  password: string;
  setPassword(s: string): void;
  hasTrialBanner: boolean;
  setHasTrialBanner(b: boolean): void;
  confirmPassword: string;
  setConfirmPassword(s: string): void;
  errors: IErrors;
  handleSubmit(): void;
  startLoading: boolean;
  setStartLoading(b: boolean): void;
}

const Context = createContext<CreateInstallationModalContextData>(
  {} as CreateInstallationModalContextData
);

export const useCreateInstallationModalContext = () => useContext(Context);

const CreateInstallationContext: React.FC<CreateInstallationModalProps> = ({ isOpen, onClose }) => {
  const { customer, loadCustomer, setInstallationModalOpen } = useCustomerContext();

  const [loading, setLoading] = useState(false);
  const [startLoading, setStartLoading] = useState(false);
  const [cnpj, setCnpj] = useState("");
  const [businessModel, setBusinessModel] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({} as IErrors);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [yoogaCustomerId, setYoogaCustomerId] = useState("");
  const [name, setName] = useState("");
  const [hasTrialBanner, setHasTrialBanner] = useState(true);

  const handleSubmit = () => {
    const payload: CreateInstallationRequestData = {
      cnpj: cnpj.replace(/\D/g, ""),
      negocio: businessModel,
      password,
      telefone: phone,
      email,
      yooga_customer_id: yoogaCustomerId,
      nome: name,
      has_trial_banner: hasTrialBanner,
    };

    const onClose = () => {
      setInstallationModalOpen(false);
    };

    const err = validateInstallationForm(data);
    setErrors(err);

    if (Object.values(err).length) return;

    setLoading(true);
    create(payload, customer?.id)
      .then((data: AxiosResponse<{ idi: number }>) => {
        toast.success("Instalação criada com sucesso");
        onClose();
        loadCustomer();
      })
      .catch((err: AxiosError<{ message: string }>) => {
        if (err?.response?.data?.message?.toLowerCase().includes("cnpj já vinculado")) {
          toast.error("Documento já cadastrado");
        } else {
          toast.error("Erro ao criar instalação");
          console.error(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    return;
  };

  useEffect(() => {
    setPhone(customer?.phone ?? "");
    setEmail(customer?.email ?? "");
    customer?.yoogaCustomerId && setYoogaCustomerId(customer.yoogaCustomerId);
    setName(customer?.tradeName ?? customer?.name ?? customer?.document);
  }, [customer]);

  const data = {
    loading,
    setLoading,
    cnpj,
    setCnpj,
    businessModel,
    setBusinessModel,
    password,
    setPassword,
    confirmPassword,
    setConfirmPassword,
    errors,
    handleSubmit,
    startLoading,
    setStartLoading,
    hasTrialBanner,
    setHasTrialBanner,
  };

  return (
    <Context.Provider value={data}>
      <CreateInstallationModal isOpen={isOpen} onClose={onClose} />
    </Context.Provider>
  );
};

export default CreateInstallationContext;
