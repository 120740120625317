import { Card, CardBody } from "@chakra-ui/react";
import BaseLogs from "shared/components/Logs/BaseLogs";
import LogsHeader from "./components/LogsCardHeader";
import { useLogContext } from "./LogsContext";

const Logs = () => {
  const { isLoadingLogs, logs, page, setPage, logsLastPage, setLogsFilters, logsFilters } =
    useLogContext();

  return (
    <Card>
      <LogsHeader />
      <CardBody>
        <BaseLogs
          isLoadingLogs={isLoadingLogs}
          logs={logs}
          page={page}
          setPage={setPage}
          logsLastPage={logsLastPage}
          setLogsFilters={setLogsFilters}
          logsFilters={logsFilters}
        />
      </CardBody>
    </Card>
  );
};

export default Logs;
