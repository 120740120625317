import {
  Button,
  Card,
  CardBody,
  Divider,
  DrawerBody,
  Heading,
  HStack,
  Link,
  Stack,
  Text,
} from "@chakra-ui/react";
import { R$ } from "shared/helpers/currency";
import { PaymentMethods } from "shared/types/payment";
import { useContractModalContext } from "../ModalContext";
import AddedServicesList from "./AddedServicesList";

const Confirmation = () => {
  const {
    contract,
    paymentMethod,
    periodicity,
    getUpsellPrice,
    getPlanPricesSum,
    paymentDay,
    loading,
    handleSubmit,
  } = useContractModalContext();

  const upsellRulesLink =
    "https://www.notion.so/Clusteriza-o-de-Upsell-2a1f37240ee641c086083f908cdd5339";

  return (
    <DrawerBody scrollBehavior="auto">
      <Stack py="1rem" minH="100%">
        <HStack>
          <Text fontSize="0.9rem" fontWeight="bold">
            Forma de pagamento:
          </Text>
          <Text fontSize="0.9rem">
            {contract ? contract?.paymentMethodName : PaymentMethods[paymentMethod]}
          </Text>
        </HStack>

        {!contract && (
          <HStack>
            <Text fontSize="0.9rem" fontWeight="bold">
              Dia do vencimento:
            </Text>
            <Text fontSize="0.9rem">Todo dia {paymentDay}</Text>
          </HStack>
        )}

        {!!contract && periodicity === "monthly" && (
          <Card color="white" bg="red.300">
            <CardBody>
              <Text>
                <strong>Atenção!</strong> Uma cobrança de <strong>{getUpsellPrice()}</strong> será
                criada a partir das regras de upsell da Yooga.{" "}
                <Link href={upsellRulesLink} target="_blank" textDecoration="underline">
                  Clique aqui para entender sobre as regras.
                </Link>
              </Text>
            </CardBody>
          </Card>
        )}

        <Divider my="2rem" />

        <AddedServicesList variant="unstyled" />

        <Divider my="2rem" />

        <HStack justify="space-between">
          <Text fontSize="1.2rem" color="gray.500">
            Valor total
          </Text>
          <Heading size="lg">{R$(getPlanPricesSum())}</Heading>
        </HStack>

        <Button
          mt="auto"
          colorScheme="yooga"
          isLoading={loading}
          isDisabled={loading}
          onClick={handleSubmit}
        >
          {!contract ? "Concluir contrato" : "Adicionar serviços"}
        </Button>
      </Stack>
    </DrawerBody>
  );
};

export default Confirmation;
