import { Flex, Stack, Text } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { formatLogs } from "shared/helpers/logs";
import { AppLogEntry } from "shared/types/installation/app/appLog";
import { DeliveryOrderLogEntry } from "shared/types/installation/delivery/deliveryLog";
import FiltersHeader from "./FiltersHeader";
import LogModal from "./LogModal/LogModal";
import LogsTable from "./LogsTable";
import PaginationFooter from "./PaginationFooter";

interface BaseLogsProps {
  isLoadingLogs: boolean;
  logs: (AppLogEntry | DeliveryOrderLogEntry)[];
  page: number;
  setPage: (page: number) => void;
  logsLastPage: number;
  setLogsFilters: (filters: any) => void;
  logsFilters: any;
  statusLabels?: Record<string, string>;
  showOrderId?: boolean;
  title?: string;
  wrapper?: React.ComponentType<{ children: React.ReactNode }>;
}

const BaseLogs: React.FC<BaseLogsProps> = ({
  isLoadingLogs,
  logs,
  page,
  setPage,
  logsLastPage,
  setLogsFilters,
  logsFilters,
  statusLabels,
  showOrderId = false,
  title,
  wrapper: Wrapper = ({ children }) => <>{children}</>,
}) => {
  const [selectedLog, setSelectedLog] = useState<AppLogEntry | DeliveryOrderLogEntry | null>(null);
  const [isLogModalOpen, setIsLogModalOpen] = useState(false);

  const openLog = (log: AppLogEntry | DeliveryOrderLogEntry) => {
    setSelectedLog(log);
    setIsLogModalOpen(true);
  };

  const formattedLogs = useMemo(() => {
    return formatLogs(logs);
  }, [logs]);

  if (isLoadingLogs) {
    return (
      <Wrapper>
        <Flex justify="center" align="center" height="100px">
          <Text>Carregando...</Text>
        </Flex>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Stack spacing={4}>
        <FiltersHeader
          setLogsFilters={setLogsFilters}
          statusFilterName="Status"
          statusLabels={statusLabels}
          logsFilters={logsFilters}
          showOrderId={showOrderId}
        />

        {!isLoadingLogs && logs.length === 0 ? (
          <Flex justify="center" align="center" height="100px">
            <Text>Nenhum log encontrado.</Text>
          </Flex>
        ) : (
          <LogsTable logs={formattedLogs} onOpenLog={openLog} showOrderId={showOrderId} />
        )}

        {logs?.length > 0 && (
          <PaginationFooter
            visibleLength={logs.length}
            page={page}
            setPage={setPage}
            pageCount={logsLastPage}
          />
        )}
      </Stack>

      {selectedLog && (
        <LogModal
          isOpen={isLogModalOpen}
          onClose={() => setIsLogModalOpen(false)}
          log={selectedLog}
          title={
            title || (selectedLog.type === "Installation" ? "Log de Instalação" : "Log de Pedido")
          }
        />
      )}
    </Wrapper>
  );
};

export default BaseLogs;
