import {
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";

export type ConfirmModalProps = React.PropsWithChildren<{
  isOpen: boolean;
  onClose(): void;
  title?: string;
  content?: string | JSX.Element;
  buttonText?: string;
  onConfirm?(): void;
  showCloseButton?: boolean;
  confirmByText?: boolean;
}>;

const ConfirmModal: React.FC<ConfirmModalProps> = ({
  isOpen,
  onConfirm,
  title = "Deseja continuar?",
  content,
  buttonText = "Confirmar",
  onClose,
  children,
  showCloseButton = true,
  confirmByText = false,
}) => {
  const [text, setText] = useState("");
  const [isConfirming, setIsConfirming] = useState(true);

  useEffect(() => {
    setText("");
    setIsConfirming(false);
  }, [isOpen]);

  const handleConfirm = async () => {
    if (isConfirming) return;
    setIsConfirming(true);

    await new Promise((resolve) => setTimeout(resolve, 100));
    // Pequeno delay para permitir que o React processe a atualização do estado `isConfirming` antes de continuar.
    // Sem isso, o botão pode ser clicado múltiplas vezes antes da atualização do estado.

    try {
      onConfirm?.();
    } finally {
      setIsConfirming(false);
      onClose();
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{title}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {content}
          {children}

          {confirmByText && (
            <Input
              mt="0.5rem"
              value={text}
              onChange={(e) => setText(e.target.value)}
              placeholder="Digite CONFIRMAR"
            />
          )}
        </ModalBody>

        <ModalFooter>
          {showCloseButton && (
            <Button variant="outlined" mr={3} onClick={onClose}>
              Fechar
            </Button>
          )}

          <Button
            variant="filled"
            onClick={handleConfirm}
            isLoading={isConfirming}
            isDisabled={confirmByText && text !== "CONFIRMAR"}
          >
            {buttonText}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ConfirmModal;
