import { AxiosResponse } from "axios";
import { apiNestNode, apiV2 } from "../http";
import {
  CreateInstallationRequestData,
  CustomerKeys,
  InstallationCheckResponse,
  PauseRequest,
} from "../interfaces/installation/installation";

export function checkInstallations(params: CustomerKeys) {
  return apiV2.get<InstallationCheckResponse>("customers/check", { params });
}

export function pauseAutomation(idi: number, payload: PauseRequest): Promise<AxiosResponse> {
  return apiNestNode.post(`/installation/${idi}/pause-automation`, payload);
}

export function create(
  payload: CreateInstallationRequestData,
  customerId: number
): Promise<AxiosResponse> {
  return apiNestNode.post("/installation/create", { ...payload, customer_id: customerId });
}
